import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import { TiptapVuetifyPlugin } from "tiptap-vuetify"
import 'tiptap-vuetify/dist/main.css'
import Vuetify from 'vuetify/lib/framework'
import en from 'vuetify/es5/locale/en'
import ru from 'vuetify/es5/locale/ru'

const vuetify = new Vuetify({
    lang: {
        locales: { en, ru },
        current: 'ru'
    },
    icons: {
        iconfont: 'fa'
    }
})

Vue.use(Vuetify)
Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'mdi'
})

export default vuetify
