const state = {
    pagination: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 25,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: ['username'],
        sortDesc: [false]
    },
    filters: [],
    arbitrPagination: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 25,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: ['username'],
        sortDesc: [false]
    }
}

const mutations = {
    setPagination (state, value) {
        state.pagination = value
    },
    setFilters (state, value) {
        state.filters = value
    },
    setArbitrPagination (state, value) {
        state.arbitrPagination = value
    }
}

const actions = {
    getUserList ({ dispatch }, params = {}) {
        return dispatch('_get', { path: '/api/v1/admin/users', params: params }, { root: true })
    },
    getUserData ({ dispatch }, userId) {
        return dispatch('_get', { path: `/api/v1/admin/user/${userId}` }, { root: true })
    },
    sotGenerate ({ dispatch }, payload) {
        return dispatch('_post', { path: '/api/v1/admin/sot/generate', payload: payload }, { root: true })
    },
    getSotCount ({ dispatch }, params = {}) {
        return dispatch('_get', { path: '/api/v1/admin/sot/count', params: params }, { root: true })
    },
    addSot ({ dispatch }, { userId, payload }) {
        return dispatch('_post', { path: `/api/v1/admin/user/${userId}/add_sot`, payload: payload }, { root: true })
    },
    changeCommission ({ dispatch }, { userId, payload }) {
        return dispatch('_post', { path: `/api/v1/admin/user/${userId}/commission`, payload: payload}, { root: true })
    },
    changeStatus ({ dispatch }, { userId, payload }) {
        return dispatch('_post', { path: `/api/v1/admin/user/${userId}/status`, payload: payload}, { root: true })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
