import axios from "axios";
import jwtDecode from "jwt-decode";
import router from "../router";

const state = {
    token: localStorage.getItem('data-token') || '',
    status: ''
}

const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    checkPermission: state => permission =>
        Boolean(state.token) && (Array.isArray(permission) ? permission : [permission]).some(
            permission => jwtDecode(state.token).roles.includes(permission)
        ),
    userId: state => parseInt(jwtDecode(state.token).sub)
}

const mutations = {
    authRequest(state) {
        state.status = 'loading'
    },
    authSuccess(state, token) {
        state.status = 'success'
        state.token = token
    },
    authError(state) {
        state.status = 'error'
    },
    authLogout(state) {
        state.token = ''
        state.status = ''
    }
}

const actions = {
    login ({ commit, dispatch }, { username, password, client_secret }) {
        commit('authRequest')
        const payload = new URLSearchParams()
        payload.append('username', username)
        payload.append('password', password)
        if (client_secret) payload.append('client_secret', client_secret)
        return dispatch('_post', { path: '/api/v1/auth/create', payload }, { root: true }).then(response => {
            if (response.data_token === 'otp') {
                return 'otp'
            }
            if (response.data_token) {
                localStorage.setItem('data-token', response.data_token)
                commit('authSuccess', response.data_token)
            }
            return response
        }).catch(error => {
            commit('authError', error)
            clearStorage()
            throw error
        })
    },
    logout ({ commit, dispatch }) {
        dispatch('_post', { path: '/api/v1/auth/logout' }, { root: true }).then(() => {
            commit('authLogout')
            clearStorage()
        })
    },
    check ({ dispatch }) {
        dispatch('_post', { path: '/api/v1/auth/check'}, { root: true })
    },
    term ({ dispatch }) {
        return dispatch('_get', { path: '/api/v1/option/term' }, { root: true })
    },
    registration ({ commit, dispatch }, payload ) {
        return dispatch('_post', { path: '/api/v1/users/registration', payload: payload }, { root: true }).then(() => {
            clearStorage()
        }).catch(error => {
            commit('authError', error)
            clearStorage()
            throw error
        })
    },
    activation ({ dispatch }, uuid) {
        return dispatch('_post', { path: '/api/v1/users/activate', payload: { uuid: uuid } }, { root: true })
    },
    recovery ({ dispatch }, email) {
        return dispatch('_post', { path: '/api/v1/password-recovery', payload: { email: email } }, { root: true })
    },
    preResetPassword ({ dispatch }, payload) {
        return dispatch('_post', { path: '/api/v1/pre-reset-password', payload }, { root: true })
    },
    resetPassword ({ dispatch }, payload) {
        return dispatch('_post', { path: '/api/v1/reset-password', payload }, { root: true })
    },
    get2Way ({ dispatch }) {
        return dispatch('_get', { path: '/api/v1/auth/two-way' }, { root: true })
    },
    getQRCode ({ dispatch }) {
        return dispatch('_get', { path: '/api/v1/auth/2way-qrcode' }, { root: true })
    },
    postAuthCode ({ dispatch }, code) {
        return dispatch('_post', { path: '/api/v1/auth/two-way-code', payload: { code: code } }, { root: true })
    },
    delete2Way ({ dispatch }) {
        return dispatch('_delete', { path: '/api/v1/auth/two-way'}, { root: true })
    },
    async refresh ({ dispatch }) {
        try {
            await dispatch('_post', { path: '/api/v1/auth/refresh' }, { root: true })
            return Promise.resolve()
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async interceptor ({ dispatch }, error) {
        try {
            const {config, response: {status}} = error
            if (status === 422 && !~config.url.indexOf('/auth/refresh')) {
                await dispatch('refresh')
                const response = await axios.request(config)
                return Promise.resolve(response)
            }
            if (status === 401) {
                dispatch('logout')
                // await router.push({ name: 'Login' })
                return Promise.reject(error)
            }
        } catch (e) {
            if (e.response.status === 406) {
                return Promise.reject(e)
            }
            dispatch('logout')
            await router.push({ name: 'Login' })
            return Promise.reject(e)
        }
        return Promise.reject(error)
    }
}

function clearStorage() {
    localStorage.removeItem('data-token')
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
