const state = {
    pagination: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 25,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: ['name'],
        sortDesc: [false]
    },
    filters: [],
    productsPagination: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 25,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: ['name'],
        sortDesc: [false]
    },
    productsFilters: [],
    orderDialog: false,
    // productDialog: false
}

const mutations = {
    setPagination (state, value) {
        state.pagination = value
    },
    setFilters (state, value) {
        state.filters = value
    },
    setProductsPagination (state, value) {
        state.productsPagination = value
    },
    setProductsFilters (state, value) {
        state.productsFilters = value
    },
    openOrderDialog (state) {
        state.orderDialog = true
    },
    closeOrderDialog (state) {
        state.orderDialog = false
    },
    // openProductDialog (state) {
    //     state.productDialog = true
    // },
    // closeProductDialog (state) {
    //     state.productDialog = false
    // },
}

const actions = {
    getCategoryList ({ dispatch }, params = {}) {
        return dispatch('_get', { path: '/api/v1/store/list', params: params }, { root: true })
    },
    getProductsList ({ dispatch }, {id, params = {} }) {
        return dispatch('_get', { path: `/api/v1/store/category/${id}`, params: params }, { root: true })
    },
    sendOrder ({ dispatch }, { id, payload }) {
        return dispatch('_post', { path: `/api/v1/store/product/${id}`, payload: payload }, { root: true })
    },
    getOrderList ({ dispatch }) {
        return dispatch('_get', { path: '/api/v1/store/orders'}, { root: true })
    },
    listCategories ({ dispatch }) {
        return dispatch('_get', { path: `/api/v1/store/categories` }, { root: true })
    },
    // createProduct ({ dispatch }, { id, payload }) {
    //     return dispatch('_post', { path: `/api/v1/admin/magazine/${id}/product`, payload: payload }, { root: true })
    // },
    // editProduct ({ dispatch }, { id, payload }) {
    //     return dispatch('_put', { path: `/api/v1/admin/magazine/${id}/product`, payload: payload}, { root: true })
    // },
    // changeStatus ({ dispatch }, { userId, payload }) {
    //     return dispatch('_post', { path: `/api/v1/admin/user/${userId}/status`, payload: payload}, { root: true })
    // }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
