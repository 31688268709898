import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from './http'
import vuetify from './plugins/vuetify'
import EventBus from './plugins/eventbus'
import './plugins/veevalidate'
import './plugins/filters'
import FlashMessage from '@smartweb/vue-flash-message'
import VueMask from 'v-mask'
import moment from './plugins/moment'
import VTooltip from 'v-tooltip'
import VueNativeSock from 'vue-native-websocket'
import VueGtag from 'vue-gtag'
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

import { localize } from "vee-validate"
import en from 'vee-validate/dist/locale/en.json'
import ru from 'vee-validate/dist/locale/ru.json'

localize({ ru, en })
localize('ru', ru)

Vue.config.productionTip = false

Vue.use(VTooltip)
Vue.use(require('vue-moment'), { moment })
Vue.use(FlashMessage, {
    name: 'flashMessage',
    tag: 'FlashMessage',
    time: 5000,
    strategy: 'multiple'
})
Vue.use(VueMask)
Vue.use(VueNativeSock, 'ws://localhost:8080', {
    format: 'json',
    connectManually: true,
    reconnection: true
})
Vue.use(VuetifyDialog, {
    context: { vuetify },
    confirm: {
        actions: {
            false: 'Нет',
            true: { text: 'Да', color: 'primary' }
        }
    },
    prompt: {
        actions: {
            false: 'Да',
            true: { text: 'Нет', color: 'primary' }
        }
    }
})

Vue.prototype.$eventBus = EventBus

Vue.use(VueGtag, {
    config: { id: 'G-RBJ2669XGH' }
}, router)

new Vue({
  http: http,
  router: router,
  store: store,
  vuetify: vuetify,
  render: h => h(App)
}).$mount('#app')
