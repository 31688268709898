<template>
  <v-main>
    <v-app-bar clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        app
        clipped
    >
      <v-card flat>
        <v-list class="align-start mb-auto">
          <template v-for="(item, key) in menu">
            <template v-if="item.items">
              <v-list-group
                  :key="key"
                  :prepend-icon="item.icon"
                  no-action
                  v-if="$store.getters['auth/checkPermission'](item.access)"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"/>
                  </v-list-item-content>
                </template>
                <template v-for="(i, k) in item.items">
                  <v-list-item
                      :key="k"
                      :to="i.to"
                      v-if="$store.getters['auth/checkPermission'](item.access)"
                      dense
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="i.title"/>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon v-text="i.icon"/>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </v-list-group>
            </template>
            <template v-else>
              <v-list-item
                  :key="key"
                  :to="item.to"
                  v-if="$store.getters['auth/checkPermission'](item.access)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"/>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"/>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>
        </v-list>
      </v-card>
    </v-navigation-drawer>
    <router-view/>
  </v-main>
</template>

<script>
export default {
  name: "IndexView",
  data: () => ({
    menu: [
      {
        title: 'Главная',
        to: '/',
        access: 'admin',
        icon: 'fas fa-home'
      },
      {
        title: 'Панель',
        to: '/admin',
        access: 'admin',
        icon: 'fas fa-tachometer-alt'
      },
      {
        title: 'Пользователи',
        to: '/admin/users',
        access: 'admin',
        icon: 'fas fa-users',
      },
      {
        title: 'Настройки',
        to: '/admin/settings',
        access: 'admin',
        icon: 'fas fa-cog'
      },
      {
        title: 'СОТ',
        to: '/admin/coins',
        access: 'admin',
        icon: 'fas fa-coins'
      },
      {
        title: 'Магазин',
        access: 'admin',
        icon: 'fas fa-shopping-bag',
        items: [
          {
            title: 'Список',
            to: '/admin/magazine',
            access: 'admin'
          },
          // {
          //   title: 'Заказы',
          //   to: '/admin/magazine/orders',
          //   access: 'admin'
          // }
        ],
      },
      {
        title: 'Арбитраж',
        to: '/admin/arbitrage',
        access: 'admin',
      },
      {
        title: 'Транзакции',
        to: '/admin/trans',
        access: 'admin',
        icon: 'fas fa-database'
      },
      {
        title: 'Отчеты',
        access: 'admin',
        icon: 'fas fa-folder',
        items: [
          {
            title: 'Объемы',
            to: '/admin/reports/volume',
            access: 'admin'
          }
        ]
      }
    ]
  }),
  computed: {
    drawer: {
      get: function () { return this.$store.state.drawer },
      set: function (value) { this.$store.commit('setDrawer', value) }
    }
  }
}
</script>

<style scoped>

</style>
