const state = {
    pagination: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 25,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: ['username'],
        sortDesc: [false]
    },
    filters: []
}

const mutations = {
    setPagination (state, value) {
        state.pagination = value
    },
    setFilters (state, value) {
        state.filters = value
    }
}

const actions = {
    getList ({ dispatch }, params = {}) {
        return dispatch('_get', { path: '/api/v1/admin/arbitrage', params: params }, { root: true })
    },
    getOrder ({ dispatch }, orderId) {
        return dispatch('_get', { path: `/api/v1/admin/arbitrage/${orderId}` }, { root: true })
    },
    postOrder ({dispatch}, { id, payload }) {
        return dispatch('_post', { path: `/api/v1/admin/arbitrage/${id}`, payload}, { root: true })
    },
    getMessages ({ dispatch }, id) {
        return dispatch('_get', { path: `/api/v1/admin/arbitrage/messages/${id}` }, { root: true })
    },
    sendMessage ({ dispatch }, { id, payload }) {
        return dispatch('_post', { path: `/api/v1/admin/arbitrage/message/${id}`, payload }, { root: true })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
