import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

// axios.defaults.baseURL = process.env.VUE_APP_API_URL
Vue.use(VueAxios, axios)

// const access_token = localStorage.getItem('access-token') || ''
// if (access_token) {
//     axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
// }

export default {
    root: process.env.VUE_APP_API_URL
}