import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Index from '../views/Index'
import AdminIndex from '../views/admin/Index'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Index,
    children: [
      {
        path: '',
        name: 'Main',
        component: Main
      },
      {
        path: 'registration',
        name: 'Registration',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/Registration')
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/Login')
      },
      {
        path: 'activate/:uuid',
        name: 'Activate',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/Activation')
      },
      {
        path: 'recovery-password',
        name: 'RecoveryPassword',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/RecoveryPassword')
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/ResetPassword')
      },
      {
        path: 'cabinet',
        name: 'Cabinet',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/Cabinet'),
        meta: { auth: true }
      },
      {
        path: 'support',
        name: 'Support',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/Support')
      },
      {
        path: 'about',
        name: 'About',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/About')
      },
      {
        path: 'user-guide',
        name: 'UserGuide',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/UserGuide')
      },
      {
        path: 'operation',
        name: 'Operation',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/Operation'),
        redirect: '/operation/sell',
        children: [
          {
            path: 'sell',
            name: 'Sell',
            component: () => import(/* webpackChunkName: "Chunk" */ '../views/Sell')
          },
          {
            path: 'buy',
            name: 'Buy',
            component: () => import(/* webpackChunkName: "Chunk" */ '../views/Buy')
          },
          {
            path: 'change',
            name: 'Change',
            component: () => import(/* webpackChunkName: "Chunk" */ '../views/Change'),
            meta: { auth: true }
          }
        ]
      },
      {
        path: 'preorder/:id',
        name: 'PreOrder',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/PreOrder'),
        meta: { auth: true }
      },
      {
        path: 'order/:id',
        name: 'Order',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/Order'),
        meta: { auth: true }
      },
      {
        path: 'shop',
        name: 'Shop',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/Shop'),
        meta: { auth: true }
      },
      {
        path: 'shop/:id',
        name: 'Products',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/ShopProduct'),
        meta: { auth: true }
      }
    ]
  },
  {
    path: '/admin',
    component: AdminIndex,
    meta: { auth: 'admin' },
    children: [
      {
        path:'',
        name: 'AdminDashboard',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/admin/Dashboard'),
        meta: { auth: 'admin' }
      },
      {
        path: 'users',
        name: 'AdminUsers',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/admin/users/List'),
        meta: { auth: 'admin' }
      },
      {
        path: 'user/:id',
        name: 'AdminUser',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/admin/users/User'),
        meta: { auth: 'admin' }
      },
      {
        path: 'coins',
        name: 'AdminCoins',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/admin/coins/Sot'),
        meta: { auth: 'admin' }
      },
      {
        path: 'trans',
        name: 'AdminTrans',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/admin/Trans'),
        meta: { auth: 'admin' }
      },
      {
        path: 'arbitrage',
        name: 'ArbitrageList',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/admin/arbitrage/List'),
        meta: { auth: 'admin' }
      },
      {
        path: 'arbitrage/:id',
        name: 'ArbitrageView',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/admin/arbitrage/View'),
        meta: { auth: 'admin' }
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/admin/Settings'),
        meta: { auth: 'admin' }
      },
      {
        path: 'reports/volume',
        name: 'ReportVolume',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/admin/reports/Volume'),
        meta: { auth: 'admin' }
      },
      {
        path: 'magazine',
        name: 'CategoryList',
        component: () => import(/* webpackChunkName: "Chunk" */ '../views/admin/magazine/Index'),
        meta: { auth: 'admin' }
      },
      // {
      //   path: 'magazine/category/:id',
      //   name: 'ProductList',
      //   component: () => import(/* webpackChunkName: "Chunk" */ '../views/admin/magazine/Products'),
      //   meta: { auth: 'admin' }
      // },
      // {
      //   path: 'magazine/orders',
      //   name: 'OrdersList',
      //   component: () => import(/* webpackChunkName: "Chunk" */ '../views/admin/magazine/Order'),
      //   meta: { auth: 'admin' }
      // },
      // {
      //   path: 'magazine/orders/view',
      //   name: 'OrdersView',
      //   component: () => import(/* webpackChunkName: "Chunk" */ '../components/OrderMagazineForm'),
      //   meta: { auth: 'admin' }
      // }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  hashbang: false,
  base: __dirname,
  routes
})

const isBoolean = val => 'boolean' === typeof val

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (isBoolean(to.meta.auth)) {
      if (!(to.meta.auth && store.getters['auth/isAuthenticated'])) {
        store.commit('setNotification', {status: 'error', title: 'Доступ запрещен.', message: 'Нет прав для доступа.'})
        next({ path: '/login' })
      } else {
        next()
      }
    } else {
      store.dispatch('auth/check').catch(() => {
        next({path: '/login'})
      })
      if (to.meta.auth && store.getters["auth/checkPermission"](to.meta.auth)) {
        next()
      } else {
        store.commit('setNotification', {status: 'error', title: 'Доступ запрещен.', message: 'Нет прав для доступа.'})
        next({path: from.fullPath})
      }
    }
  } else {
    store.dispatch('auth/check').catch(() => {
      store.dispatch('auth/logout').then(() => {})
    }).finally(() => {
      next()
    })
  }
})

export default router
