const state = {

}

const mutations = {

}

const actions = {
    sendMessage ({ dispatch }, payload) {
        return dispatch('_post', { path: '/api/v1/support', payload }, { root: true })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}