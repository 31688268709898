<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="6">
        <div class="text-lg-h1 text-h3 mt-lg-12 font-weight-bold mb-lg-12" style="color: white">
          Change Market
<!--            Место новых возможностей-->
        </div>
      </v-col>
    </v-row>
<!--    <v-row>-->
<!--      <v-col cols="12" lg="5">-->
<!--        <div class="text-body-1 font-weight-normal" style="color: white">-->
<!--          Ключ к успеху — это каждая минута твоего времени. Чтобы изменить свою жизнь к лучшему сделай первый шаг вместе с нами, здесь и сейчас!-->
<!--        </div>-->
<!--      </v-col>-->
<!--    </v-row>-->
    <v-row>
      <v-col cols="12" class="d-flex justify-start">
        <v-hover v-if="!isAuthenticated">
          <template v-slot:default="{ hover }">
            <v-btn rounded x-large class="button-registration" :elevation="hover ? 24 : 6" to="/registration" v-if="!isAuthenticated">
              <span class="mx-6 font-weight-bold">Регистрация</span>
            </v-btn>
          </template>
        </v-hover>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col cols="12" lg="3">
        <v-hover>
          <template v-slot:default="{ hover }">
            <router-link to="/operation/sell" style="text-decoration: none">
              <v-card
                outlined
                height="210"
                class="mr-5 c-card rounded-xl d-flex flex-column justify-center align-center transition-swing"
                :elevation="hover ? 24 : 6"
                dark
              >
                <v-img :src="require('@/assets/sell.png')" max-height="52" max-width="31.5"/>
                <span class="text-uppercase font-weight-bold mt-3" style="font-size: 21px">Продажа</span>
              </v-card>
            </router-link>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" lg="3">
        <v-hover>
          <template v-slot:default="{ hover }">
            <router-link to="/operation/buy" style="text-decoration: none">
              <v-card
                outlined
                height="210"
                class="mr-5 c-card rounded-xl d-flex flex-column justify-center align-center transition-swing"
                :elevation="hover ? 24 : 6"
                dark
              >
                <v-img :src="require('@/assets/bay.png')" max-height="52" max-width="31.5"/>
                <span class="text-uppercase font-weight-bold mt-3" style="font-size: 21px">Покупка</span>
              </v-card>
            </router-link>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" lg="3">
        <v-hover>
          <template v-slot:default="{ hover }">
            <router-link to="/operation/change" style="text-decoration: none">
              <v-card
                outlined
                height="210"
                class="mr-5 c-card rounded-xl d-flex flex-column justify-center align-center transition-swing"
                :elevation="hover ? 24 : 6"
                dark
              >
                <div class="d-flex flex-row">
                  <v-img :src="require('@/assets/sell.png')" max-height="52" max-width="31.5"/>
                  <v-img :src="require('@/assets/bay.png')" max-height="52" max-width="31.5"/>
                </div>
                <span class="text-uppercase font-weight-bold mt-3" style="font-size: 21px">Обмен</span>
              </v-card>
            </router-link>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" lg="3">
        <v-hover>
          <template v-slot:default="{ hover }">
            <router-link to="/shop" style="text-decoration: none">
              <v-card
                  outlined
                  height="210"
                  class="mr-5 c-card rounded-xl d-flex flex-column justify-center align-center transition-swing"
                  :elevation="hover ? 24 : 6"
                  dark
              >
                  <v-img :src="require('@/assets/donate.png')" max-height="150" max-width="150"/>
<!--                <span class="text-uppercase font-weight-bold mt-3" style="font-size: 21px">Магазин</span>-->
              </v-card>
            </router-link>
          </template>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'MainView',

    data: () => ({
    }),
    computed: {
      isAuthenticated() {
        return this.$store.getters['auth/isAuthenticated']
      }
    }
  }
</script>

<style lang="scss">

</style>
