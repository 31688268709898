const state = {
    twoWayDialog: false
}

const mutations = {
    open2WayDialog (state) {
        state.twoWayDialog = true
    },
    close2WayDialog (state) {
        state.twoWayDialog = false
    }
}

const actions = {
    getProfile ({ dispatch }) {
        return dispatch('_get', { path: '/api/v1/users/me' }, { root: true })
    },
    putProfile ({ dispatch }, payload) {
        return dispatch('_put', { path: '/api/v1/users/me', payload }, { root: true })
    },
    getCommission ({ dispatch }) {
        return dispatch('_get', { path: '/api/v1/users/commission' }, { root: true })
    },
    getBalance ({ dispatch }) {
        return dispatch('_get', { path: '/api/v1/users/balance' }, { root: true })
    },
    getTrade ({ dispatch }) {
        return dispatch('_get', { path: '/api/v1/users/trade' }, { root: true })
    },
    getShop ({ dispatch }) {
        return dispatch('_get', { path: '/api/v1/users/shop' }, { root: true })
    },
    getOrder ({ dispatch }) {
        return dispatch('_get', { path: '/api/v1/users/order' }, { root: true })
    },
    getOrderHistory ({ dispatch }) {
        return dispatch('_get', { path: '/api/v1/users/order-history' }, { root: true })
    },
    getWaitOrder ({ dispatch }) {
        return dispatch('_get', { path: '/api/v1/users/wait-order' }, { root: true })
    },
    getAbout ({ dispatch }) {
        return dispatch('_get', { path: '/api/v1/users/about' }, { root: true })
    },
    getUserGuide ({ dispatch }) {
        return dispatch('_get', { path: '/api/v1/users/user-guide' }, { root: true })
    },
    setVisibleParams ({ dispatch }, payload) {
        return dispatch('_post', { path: '/api/v1/users/set-visible', payload }, { root: true })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
