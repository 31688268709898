const state = {
    pagination: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 25,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: ['name'],
        sortDesc: [false]
    },
    filters: [],
    productsPagination: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 25,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: ['name'],
        sortDesc: [false]
    },
    productsFilters: [],
    ordersPagination: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 25,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: ['name'],
        sortDesc: [false]
    },
    ordersFilters: [],
    categoryDialog: false,
    productDialog: false,
    orderDialog: false
}

const mutations = {
    setPagination (state, value) {
        state.pagination = value
    },
    setFilters (state, value) {
        state.filters = value
    },
    setProductsPagination (state, value) {
        state.productsPagination = value
    },
    setProductsFilters (state, value) {
        state.productsFilters = value
    },
    setOrdersPagination (state, value) {
        state.ordersPagination = value
    },
    setOrdersFilters (state, value) {
        state.ordersFilters = value
    },
    openCategoryDialog (state) {
        state.categoryDialog = true
    },
    closeCategoryDialog (state) {
        state.categoryDialog = false
    },
    openProductDialog (state) {
        state.productDialog = true
    },
    closeProductDialog (state) {
        state.productDialog = false
    },
    openOrderDialog (state) {
        state.orderDialog = true
    },
    closeOrderDialog (state) {
        state.orderDialog = false
    },
}

const actions = {
    getCategoryList ({ dispatch }, params = {}) {
        return dispatch('_get', { path: '/api/v1/store/list', params: params }, { root: true })
    },
    getProductsList ({ dispatch }, {id, params = {} }) {
        return dispatch('_get', { path: `/api/v1/store/category/${id}`, params: params }, { root: true })
    },
    createCategory ({ dispatch }, payload) {
        return dispatch('_post', { path: '/api/v1/admin/store/category', payload: payload }, { root: true })
    },
    editCategory ({ dispatch }, { id, payload }) {
        return dispatch('_put', { path: `/api/v1/admin/store/category/${id}`, payload: payload }, { root: true })
    },
    createProduct ({ dispatch }, { id, payload }) {
        return dispatch('_post', { path: `/api/v1/admin/store/${id}/product`, payload: payload }, { root: true })
    },
    editProduct ({ dispatch }, { id, payload }) {
        return dispatch('_put', { path: `/api/v1/admin/store/${id}/product`, payload: payload}, { root: true })
    },
    getOrdersList ({ dispatch }, params = {}) {
        return dispatch('_get', { path: `/api/v1/admin/store/orders`, params: params}, { root: true })
    },
    getMessages ({ dispatch }, id) {
        return dispatch('_get', { path: `/api/v1/admin/store/messages/${id}` }, { root: true })
    },
    sendMessage ({ dispatch }, { id, payload }) {
        return dispatch('_post', { path: `/api/v1/admin/store/message/${id}`, payload }, { root: true })
    },
    sendAction ({ dispatch }, { id, payload }) {
        return dispatch('_post', { path: `/api/v1/admin/store/action/${id}`, payload }, { root: true })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
