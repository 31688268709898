<template>
  <v-main :style="`background-image: url(${require('@/assets/bg.png')})`" class="bg-image">
    <v-app-bar
      app
      fixed
      flat
      color="transparent"
    >
      <v-container>
        <v-card class="d-flex justify-space-between align-center" flat color="transparent">
          <div class="d-flex justify-start align-center">
            <v-app-bar-nav-icon style="color: #FEE351" v-if="isMobile" @click.stop="drawer=!drawer"></v-app-bar-nav-icon>
            <router-link to="/">
              <v-img
                v-if="!isMobile"
                alt="Change Market"
                class="shrink mr-2 mt-10"
                contain
                :src="require('@/assets/logo.png')"
                transition="scale-transition"
                width="93"
              />
            </router-link>
          </div>
          <v-spacer></v-spacer>
          <div v-if="!isMobile">
            <v-btn plain color="white" class="mr-10" to="/">Главная</v-btn>
            <v-btn plain color="white" class="mr-10" to="/operation">Операции</v-btn>
            <v-btn plain color="white" class="mr-10" to="/support">Поддержка</v-btn>
            <v-btn plain color="white" class="mr-10" to="/about">О Нас</v-btn>
            <v-btn plain color="white" class="mr-10" to="/registration" v-if="!isAuthenticated">Регистрация</v-btn>
            <v-btn plain color="white" class="mr-10" to="/cabinet" v-else>
              <v-badge
                  color="red"
                  :content="badgeContent"
                  :value="badgeContent"
              >
                Кабинет
              </v-badge>
            </v-btn>
            <v-btn plain color="white" class="mr-10" to="/admin" v-if="$store.getters['auth/checkPermission']('admin')">Админка</v-btn>
            <v-btn outlined rounded color="#FEE351" x-large to="/login" v-if="!isAuthenticated">
              <span class="mx-6">ВХОД</span>
            </v-btn>
            <v-btn outlined rounded color="#FEE351" x-large @click="logout" v-else>
              <span class="mx-6">ВЫХОД</span>
            </v-btn>
          </div>
        </v-card>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer
        v-if="isMobile"
        v-model="drawer"
        app
        class="navigation-drawer"
    >
      <div class="d-flex flex-column">
        <v-list dark>
          <v-list-item to="/">
            <v-list-item-content>Главная</v-list-item-content>
          </v-list-item>
          <v-list-item to="/operation">
            <v-list-item-content>Операции</v-list-item-content>
          </v-list-item>
          <v-list-item to="/support">
            <v-list-item-content>Поддержка</v-list-item-content>
          </v-list-item>
          <v-list-item to="/about">
            <v-list-item-content>О нас</v-list-item-content>
          </v-list-item>
          <v-list-item to="/registration" v-if="!isAuthenticated">
            <v-list-item-content>Регистрация</v-list-item-content>
          </v-list-item>
          <v-list-item to="/cabinet" v-else>
              <v-list-item-content>
                <v-badge
                  color="red"
                  :content="badgeContent"
                  :value="badgeContent"
                >
                  Кабинет
                </v-badge>
              </v-list-item-content>
          </v-list-item>
          <v-list-item to="/admin" v-if="$store.getters['auth/checkPermission']('admin')">
            <v-list-item-content>Админка</v-list-item-content>
          </v-list-item>
          <v-list-item to="/login" v-if="!isAuthenticated">
            <v-list-item-content>Вход</v-list-item-content>
          </v-list-item>
          <v-list-item @click="logout" v-else>
            <v-list-item-content>Выход</v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
    <router-view/>
    <v-footer
        app
        dark
        style="background: rgba(47, 23, 99, 0.6)"
    >
      <v-row>
        <v-col class="d-flex justify-space-between">
          <span>(с) Copyright СОТ - 2021</span>
          <span><a href="/user-guide" target="_blank" class="text-decoration-none" style="color: #FFFFFF">Руководство пользователя</a></span>
        </v-col>
      </v-row>
    </v-footer>
  </v-main>
</template>

<script>
export default {
  name: "IndexView",
  data: () => ({
    drawer: true,
    badgeContent: 0,
  }),
  mounted() {
    this.getData()
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated']
    }
  },
  methods: {
    getData() {
      this.$store.dispatch('user/getWaitOrder').then(response => {
        this.badgeContent = response.value
      })
    },
    logout() {
      this.$store.dispatch('auth/logout')
          .then(() => {
            this.$router.push({ path: '/' })
          })
    }
  }
}
</script>

<style lang="scss">
@import "../sass/common";
 .bg-image {
   background-repeat: no-repeat;
   background-position: center center;
   background-attachment: fixed;
   background-size: cover;
 }
</style>
