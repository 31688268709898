import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email, min_value, max_value, digits, min, max, regex } from "vee-validate/dist/rules"

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)

extend('required', {
    ...required,
    message: 'Поле обязательно для заполнения'
})
extend('email', {
    ...email,
})
extend('min_value', {
    ...min_value
})
extend('max_value', {
    ...max_value
})
extend('digits', {
    ...digits
})
extend('min', {
    ...min
})
extend('max', {
    ...max
})
extend('max_ge_min', {
    params: ['target'],
    validate: (value, { target }) => {
        return parseInt(value) >= parseInt(target)
    },
    message: 'Максимальное значение должно быть больше или равно минимальному'
})
extend('total_le_balance', {
    params: ['total', 'balance'],
    validate: (value, { total, balance }) => {
        return parseInt(total) <= parseInt(balance)
    },
    message: 'Запрашиваемое количество СОТ больше максимально допустимого'
})
extend('coins_max', {
  params: ['target'],
  validate: (value, { target }) => {
    return parseInt(value) <= parseInt(target)
  },
  message: 'Запрашиваемое количество СОТ больше максимально допустимого'
})
extend('phone', {
  params: ['phoneValidate'],
  validate: (value, { phoneValidate }) => {
    return phoneValidate
  },
  message: 'Некоректный номер телефона'
})
extend('password', {
  params: ['target'],
  validate: (value, { target }) => {
    return value === target
  },
  message: 'Пароли не совпадают'
})
extend('term', {
  validate: (value) => {
    return value
  },
  message: 'Необходимо принять пользовательское соглашение'
})
extend('regex', {
    ...regex
})
extend('regex_login', {
  ...regex,
  message: 'Логин может содержать буквы латинского алфавита, цифры и _'
})

