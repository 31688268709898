const state = {
    pagination: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: ['code'],
        sortDesc: [false]
    }
}

const mutations = {
    setPagination (state, value) {
        state.pagination = value
    },
}

const actions = {
    getVaucherList ({ dispatch }, params = {}) {
        return dispatch('_get', { path: '/api/v1/vaucher/', params: params }, { root: true })
    },
    createVaucher ({ dispatch }, payload) {
        return dispatch('_post', { path: '/api/v1/vaucher/create', payload: payload }, { root: true })
    },
    cancelVaucher ({ dispatch }, payload) {
        return dispatch('_post', { path: '/api/v1/vaucher/cancel', payload: payload }, { root: true })
    },
    activateVaucher ({ dispatch }, payload) {
        return dispatch('_post', { path: '/api/v1/vaucher/activate', payload: payload }, { root: true })
    },
    listCategories ({ dispatch }) {
        return dispatch('_get', { path: '/api/v1/vaucher/' }, { root: true })
    },
    // createProduct ({ dispatch }, { id, payload }) {
    //     return dispatch('_post', { path: `/api/v1/admin/magazine/${id}/product`, payload: payload }, { root: true })
    // },
    // editProduct ({ dispatch }, { id, payload }) {
    //     return dispatch('_put', { path: `/api/v1/admin/magazine/${id}/product`, payload: payload}, { root: true })
    // },
    // changeStatus ({ dispatch }, { userId, payload }) {
    //     return dispatch('_post', { path: `/api/v1/admin/user/${userId}/status`, payload: payload}, { root: true })
    // }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
