<template>
  <FlashMessage/>
</template>

<script>
export default {
  name: "NotificationManager",
  created() {
    this.$eventBus.$on('showNotification', this.showNotification)
  },
  destroyed() {
    this.$eventBus.$off('showNotification', this.showNotification)
  },
  computed: {
    firstNotification() {
      return this.$store.getters.firstNotification
    }
  },
  methods: {
    showNotification(val) {
      this.flashMessage.show(val)
    }
  },
  watch: {
    firstNotification(val) {
      if (!val) return
      this.flashMessage.show(val)
      this.$store.commit('removeNotification', val)
    }
  }
}
</script>

<style scoped>

</style>
