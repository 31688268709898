const state = {
    paginationSell: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 25,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: ['users.username'],
        sortDesc: [false]
    },
    filtersSell: [],
    paginationBuy: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 25,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: ['users.username'],
        sortDesc: [false]
    },
    filtersBuy: [],
    tradeDialog: false,
    shopDialog: false,
}

const mutations = {
    setPaginationSell (state, value) {
        state.paginationSell = value
    },
    setFiltersSell (state, value) {
        state.filtersSell = value
    },
    setPaginationBuy (state, value) {
        state.paginationBuy = value
    },
    setFiltersBuy (state, value) {
        state.filtersBuy = value
    },
    openTradeDialog (state) {
        state.tradeDialog = true
    },
    closeTradeDialog (state) {
        state.tradeDialog = false
    },
    openShopDialog (state) {
        state.shopDialog = true
    },
    closeShopDialog (state) {
        state.shopDialog = false
    }
}

const actions = {
    getListSell ({ dispatch }, params = {}) {
        return dispatch('_get', { path: '/api/v1/operation/sell', params: params }, { root: true })
    },
    getListBuy ({ dispatch }, params = {}) {
        return dispatch('_get', { path: '/api/v1/operation/buy', params: params }, { root: true })
    },
    postTrade ({ dispatch }, payload) {
        return dispatch('_post', { path: '/api/v1/operation/trade', payload}, { root: true })
    },
    deleteTrade ({ dispatch }, { id }) {
        return dispatch('_delete', { path: '/api/v1/operation/trade', params: { tid: id }}, { root: true })
    },
    async getPreorderTrade ({ dispatch }, id) {
        return dispatch('_get', { path: `/api/v1/operation/preorder/${id}` }, { root: true })
    },
    postPreorderTrade ({ dispatch }, { id, payload }) {
        return dispatch('_post', { path: `/api/v1/operation/preorder/${id}`, payload }, { root: true })
    },
    postFindUser ({ dispatch }, name) {
        return dispatch('_post', { path: '/api/v1/operation/find-user', payload: { name: name } }, { root: true })
    },
    postChange ({ dispatch }, payload) {
        return dispatch('_post', { path: '/api/v1/operation/change', payload }, { root: true })
    },
    postProduct ({ dispatch }, payload) {
        return dispatch('_post', { path: '/api/v1/operation/shop', payload}, { root: true })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
