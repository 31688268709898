const state = {

}

const mutations = {

}

const actions = {
    getOrder ({ dispatch }, id) {
        return dispatch('_get', { path: `/api/v1/order/${id}` }, { root: true })
    },
    postOrder ({dispatch}, { id, payload }) {
        return dispatch('_post', { path: `/api/v1/order/${id}`, payload}, { root: true })
    },
    getMessages ({ dispatch }, id) {
        return dispatch('_get', { path: `/api/v1/order/messages/${id}` }, { root: true })
    },
    sendMessage ({ dispatch }, { id, payload }) {
        return dispatch('_post', { path: `/api/v1/order/message/${id}`, payload }, { root: true })
    },
    sendMessageTemplate ({ dispatch }, { id, payload }) {
        return dispatch('_post', { path: `/api/v1/order/message/template/${id}`, payload }, { root: true })
    },
    postArbitrage ({ dispatch }, { id, payload }) {
        return dispatch('_post', { path: `/api/v1/order/arbitrage/${id}`, payload }, { root: true })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}