import Vue from 'vue'

Vue.filter('mapType', function (value) {
    const map = new Map([
        ['sell', 'Продажа'],
        ['buy', 'Покупка'],
      ])
      return map.get(value)
})

Vue.filter('mapStatusOrder', function (value) {
    const map = new Map([
        ['open', 'Открыта'],
        ['payment', 'Ожидает оплаты'],
        ['cancel', 'Отмена ордера'],
        ['de_depositing', 'Ожидает раздепонирования'],
        ['close', 'Исполнена'],
        ['arbitrage', 'Арбитраж']
      ])
      return map.get(value)
})

Vue.filter('mapStatusShopOrder', function (value) {
    const map = new Map([
        [0, 'Новая'],
        [1, 'В работе'],
        [2, 'Отклонена'],
        [10, 'Закрыта']
    ])
    return map.get(value)
})

Vue.filter('transType', function (value) {
    const map = new Map([
        ['generate', 'Генерация'],
        ['transfer', 'Перемещение'],
        ['commission', 'Комиссия'],
        ['change', 'Обмен'],
        ['create_trade', 'Создание заявки'],
        ['delete_trade', 'Удаление заявки'],
        ['request_order', 'Запрос сделки'],
        ['cancel_order', 'Отмена сделки'],
        ['order_complete', 'Завершение сделки'],
        ['arbitr_complete', 'Завершение сделки (арбитр)'],

        ['sell', 'Продажа'],
        ['buy', 'Покупка'],
        ['to_trade', 'В заявку'],
        ['from_trade', 'Из заявки'],
        ['from_order', 'Из сделки'],
        ['to_order', 'В сделку'],

    ])
    return map.get(value)
})

Vue.filter('arbitrageType', function (value) {
    const map = new Map([
        [1, 'Проблемы с переводом денежных средств'],
        [2, 'Проблемы с переводом СОТ'],
        [3, 'Отсутствие активности оппонента']
    ])
    return map.get(value)
})
