const state = {

}

const mutations = {

}

const actions = {
    getTerm ({ dispatch }) {
        return dispatch('_get', { path: '/api/v1/admin/term' }, { root: true })
    },
    postTerm ({ dispatch }, payload) {
        return dispatch('_post', { path: '/api/v1/admin/term', payload }, { root: true })
    },
    postAbout ({ dispatch }, payload) {
        return dispatch('_post', { path: '/api/v1/admin/about', payload }, { root: true })
    },
    postUserGuide ({ dispatch }, payload) {
        return dispatch('_post', { path: '/api/v1/admin/user-guide', payload }, { root: true })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}