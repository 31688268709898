import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth.module'
import user from './user.module'
import shop from './shop.module'
import userAdmin from './userAdmin.module'
import transAdmin from './transAdmin.module'
import arbitrageAdmin from './arbitrageAdmin.module'
import settingsAdmin from './settingsAdmin.module'
import magazineAdmin from './magazineAdmin.module'
import operation from './operation.module'
import order from './order.module'
import support from './support.module'
import volumeReport from './volumeReport.module'
import vaucher from './vaucher.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    shop,
    userAdmin,
    transAdmin,
    arbitrageAdmin,
    settingsAdmin,
    magazineAdmin,
    operation,
    order,
    support,
    volumeReport,
    vaucher,
  },
  state: {
    loading: 0,
    drawer: true,
    notifications: [],
    overlay: false
  },
  getters: {
    firstNotification: state => (state.notifications.length > 0) ? state.notifications[0] : null
  },
  mutations: {
    globalLoading (state, v) {
      v ? state.loading++ : state.loading--
    },
    setDrawer (state, v) {
      state.drawer = v
    },
    setNotification (state, { status, title, message }) {
      state.notifications.push({ status, title, message })
    },
    removeNotification (state, payload) {
      state.notifications = state.notifications.filter(notification => notification !== payload)
    },
    overlayOn (state) {
      state.overlay = true
    },
    overlayOff (state) {
      state.overlay = false
    },
  },
  actions: {
    _get({ dispatch, commit }, { path, params }) {
      commit('globalLoading', true)
      return Vue.axios.get(path, { withCredentials: true, params })
          .then(response => { return dispatch('getData', response) })
          .catch(error => { dispatch('showError', error) })
          .finally(() => commit('globalLoading', false))
    },
    _post({ dispatch, commit }, { path, payload, config }) {
      commit('globalLoading', true)
      return Vue.axios.post(path, payload, { withCredentials: true, config })
          .then(response => { return dispatch('getData', response) })
          .catch(error => { dispatch('showError', error) })
          .finally(() => commit('globalLoading', false))
    },
    _put({ dispatch, commit }, { path, payload, config }) {
      commit('globalLoading', true)
      return Vue.axios.put(path, payload, { withCredentials: true, config })
          .then(response => dispatch('getData', response))
          .catch(error => { dispatch('showError', error) })
          .finally(() => { commit('globalLoading', false)})
    },
    _delete({ dispatch, commit }, { path, params }) {
      commit('globalLoading', true)
      return Vue.axios.delete(path, { params })
          .then(response => dispatch('getData', response))
          .catch(error => dispatch('showError', error))
          .finally(() => commit('globalLoading', false))
    },
    showError (_, error) {
      let title = 'Ошибка';
      // console.log('showError', error.response)
      let message = error.response.data.detail || '';
      switch (error.response.status) {
        case 500:
          title = 'Ошибка сервера';
          this._vm.flashMessage.error({ title, message })
          break;
        case 400:
          title = 'Ошибка в запросе';
          break;
        case 401:
        //   title = 'Требуется авторизация';
          throw error
        case 403:
          title = 'Доступ запрещен';
          break;
        case 404:
          title = 'Объект не найден';
          break;
      }
      // this._vm.flashMessage.error({ title, message })
      throw error
    },
    getData (_, response) {
      return response.data
    }
  }
})
