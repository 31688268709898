const state = {
    pagination: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 25,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: ['id'],
        sortDesc: [true]
    },
    filters: []
}

const mutations = {
    setPagination (state, value) {
        state.pagination = value
    },
    setFilters (state, value) {
        state.filters = value
    }
}

const actions = {
    getTransList ({ dispatch }, params = {}) {
        return dispatch('_get', { path: '/api/v1/admin/trans', params: params }, { root: true })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
